import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TeaserPage from "./TeaserPage";
import VideoTeaser from './VideoTeaser';




const Control = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<TeaserPage />} />
                    <Route path="VideoTeaser" element={<VideoTeaser />} />

                </Routes>
            </BrowserRouter>

        </div>
    )
}

export default Control
