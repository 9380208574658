import React from 'react'

const VideoTeaser = () => {
    return (
        <>

            <section className="video">
                <div className="header">
                    <a href="/" className="logo-1">
                        <img src="../../images/logo.png" alt="" />
                    </a>
                </div>
                <iframe src="https://www.youtube.com/embed/sR4e-49Obc0?autoplay=1&amp;controls=0&amp;mute=1&amp;loop=1&amp;playlist=sR4e-49Obc0&amp;showinfo=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" autoplay>
                </iframe>

                <div className="video__heading text-1">
                    <h3>Your path to new experiences</h3>
                </div>
                <div className="video__heading text-2">
                    <h3>Travel, Adventures, Personal challenges, Bucket List experiences, Giving back, or Learn a new skill.</h3>
                </div>

            </section>
            <div className="video__bottom-text">
                <h3>The Freedom platform. Coming Soon!</h3>
                <a href="mailto:Contact@Zabing.com">
                    Contact@Zabing.com
                </a>
            </div>

        </>
    )
}

export default VideoTeaser