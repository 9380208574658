import React from 'react'

const TeaserPage = () => {
    return (
        <>
            <section className="hero">
                <div className="outer-box">
                    <a href="#" className="logo">
                        <img src="../../images/logo.png" alt="logo" />
                    </a>
                    <h2>
                        Zabing is a lifestyle ecosystem built to help everyone get more out of life, we bridge the gap to new experiences.
                    </h2>
                    <a href="#" className="theme-button">
                        Download and try our Beta
                    </a>

                    <a href="/VideoTeaser/" className="video-icon" >
                        <img src="../../images/watch_icon.png" alt="watch_icon" className="img-fluid" />
                        <p>Watch The Video</p>
                    </a>

                </div>
                <div className="bottom-arrow">
                    <img src="../../images/scroll_down.png" alt="" />
                </div>
                <div className="outer-text">
                    <h3>
                        Zabing launch coming soon!
                        <br />Concierge, Community, Tokens, Gamified, NFT’s, Earn.
                    </h3>

                    <form id="earlyAccessForm" className="input-group-1">
                        <input type="email" className="theme-input-1" placeholder="Enter Your Email Address" />
                        <button className="theme-button" form="earlyAccessForm">Get Early Access </button>
                    </form>
                </div>
            </section >
        </>
    )
}

export default TeaserPage
