
import './App.css';
import Control from "./Pages/Control";

function App() {
  return (
    <>
      <Control />
    </>
  );
}

export default App;
